// ====================================================================================
// Userpools - congnito config and endpoint url settings for prod and staging
// ====================================================================================

// https://app.asana.com/0/1203712373326018/1203758596630467

// staging
export const staging = {
  cognito: {
    REGION: "eu-west-1",
    USER_POOL_ID: "eu-west-1_f2tHDd1qF",
    APP_CLIENT_ID: "1aavbc5ku7lt7st33atq2364as",
    IDENTITY_POOL_ID: "eu-west-1:38a90569-537e-4456-b46a-10f2ef1005dd",
  },
  userData: "https://staging-v2.api.audio/user/data",
  voiceBuild: "https://staging-v2.api.audio/voice-cloner/training",
  voiceStatus: "https://staging-v2.api.audio/voice-cloner/models",
  getUserData: "https://staging-v2.api.audio/voice-cloner/user",
  getText: "https://staging-v2.api.audio/voice-cloner/recording",
  zeroShot: "https://staging-v2.api.audio/voice-cloner/demo",
  aesFeedback: "https://staging-v2.api.audio/voice-cloner/feedback",
  upload: "https://staging-v2.api.audio/voice-cloner/file",
  preview: "https://staging-v2.api.audio/voice-cloner/preview",
  getFile: "https://staging-v2.api.audio/voice-cloner/file",
};
// prod
export const prod = {
  cognito: {
    REGION: "eu-west-1",
    USER_POOL_ID: "eu-west-1_6fdpK7knO",
    APP_CLIENT_ID: "40v382rropgh0g16i5fg2konbp",
    IDENTITY_POOL_ID: "eu-west-1:5324c5e5-64b9-4f3f-bb87-6218965be0ea",
  },
  userData: "https://v2.api.audio/user/data",
  voiceBuild: "https://v2.api.audio/voice-cloner/training",
  voiceStatus: "https://v2.api.audio/voice-cloner/models",
  getUserData: "https://v2.api.audio/voice-cloner/user",
  getText: "https://v2.api.audio/voice-cloner/recording",
  zeroShot: "https://v2.api.audio/voice-cloner/demo",
  aesFeedback: "https://v2.api.audio/voice-cloner/feedback",
  upload: "https://v2.api.audio/voice-cloner/file",
  preview: "https://v2.api.audio/voice-cloner/preview",
  getFile: "https://v2.api.audio/voice-cloner/file",
};
