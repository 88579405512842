// ====================================================================================
// Redux Reducer / Thunks for Auth
// ====================================================================================
import API from "@aws-amplify/api";
import Auth, { CognitoUser } from "@aws-amplify/auth";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { handleError, getRandomString } from "@utils";
import LogRocket from "logrocket";
import { readableErrors } from "@utils/ReadableErrors";
import { VoiceClonerAPI } from "@api";
import { Config } from "@config";

export interface AuthState {
  email: string | undefined;
  org: string;
  role: string;
  sub: string;
  username: string;
  name: string;
  loggedIn: boolean;
  collectName: boolean;
  authStage: "SIGN_IN" | "CONFIRMATION" | "LOGGED_IN";
  signInLoading: boolean;
  signInError: string;
  signUpLoading: boolean;
  signUpError: string;
  codeLoading: boolean;
  codeError: string;
  checkUserLoading: boolean;
  checkUserError: string;
  nameLoading: boolean;
  nameError: string;
}

const initialState: AuthState = {
  email: "",
  org: "",
  role: "",
  sub: "",
  username: "",
  name: "",
  loggedIn: false,
  collectName: false,
  authStage: "SIGN_IN",
  signInLoading: false,
  signInError: "",
  signUpLoading: false,
  signUpError: "",
  codeLoading: false,
  codeError: "",
  checkUserLoading: false,
  checkUserError: "",
  nameLoading: false,
  nameError: "",
};

interface CognitoUserExt extends CognitoUser {
  sub: string;
  email: string;
  email_verified: string;
  name: string;
  username: string;
  updated_at: string;
}

// Globals
let cognitoUser: CognitoUserExt | null;

const e2eUsers = ["endtoendtests+voicecloner@aflorithmic.ai"];

/*===================================================================*/
/* Async Thunks
/*===================================================================*/

/**
 * Handles the user signing in by email
 * @param email
 */
export const signIn = createAsyncThunk(
  "signIn",
  async (email: string, { rejectWithValue }) => {
    try {
      cognitoUser = await Auth.signIn(email);
      if (cognitoUser?.username) {
        return cognitoUser.username;
      }
      return "";
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  }
);

/**
 * Handles the user signing up
 * @param email
 * @param invite code
 */
export const signUp = createAsyncThunk(
  "signUp",
  async (
    {
      email,
      username,
      inviteCode,
    }: { email: string; username: string; inviteCode: string },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const clientMetadata = {
        inviteCode,
        // type: "ulvc",
      };
      await Auth.signUp({
        username: username,
        password: getRandomString(30),
        attributes: {
          email: email,
        },
        clientMetadata,
      });
      await dispatch(signIn(email));
    } catch (error) {
      const msg = readableErrors(handleError(error));
      return rejectWithValue(msg);
    }
  }
);

/**
 * Checks if the user is logged in
 */
export const checkUser = createAsyncThunk(
  "checkUser",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      await Auth.currentSession();
      const { attributes } = await Auth.currentAuthenticatedUser();
      const { sub } = attributes;
      const userData = await API.get("userData", "", {});

      if (
        userData?.firstName === "" ||
        userData?.firstName === "unknown" ||
        userData?.lastName === "" ||
        userData?.lastName === "unknown"
      ) {
        dispatch(authSlice.actions.setCollectName(true));
      } else {
        dispatch(authSlice.actions.setCollectName(false));
      }

      // Start logging
      if (Config.isProduction && !e2eUsers.includes(attributes.email)) {
        LogRocket.init("4ioz5n/voice-cloner");
        LogRocket.identify(attributes.email);
      }

      return {
        email: attributes.email,
        org: attributes["custom:org"],
        role: attributes["custom:role"],
        sub: attributes.sub,
        username: userData?.username,
        name: `${userData?.firstName}-${sub}`,
      };
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  }
);

/**
 * Handles the user submitting the confirmation code
 * @param code
 */
export const submitCode = createAsyncThunk(
  "submitCode",
  async (code: string, { rejectWithValue, dispatch }) => {
    try {
      if (code.length !== 6) new Error("Code is 6 numbers");
      await Auth.sendCustomChallengeAnswer(cognitoUser, code);
      await Auth.currentSession();
      cognitoUser = null;
      await dispatch(checkUser());
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  }
);

/**
 * Collect the name from the user
 * @param name
 */
export const submitName = createAsyncThunk(
  "submitName",
  async (
    {
      firstName,
      lastName,
    }: // company,
    { firstName: string; lastName: string },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const name = `${firstName}_${lastName}`;
      // const { attributes } = await Auth.currentAuthenticatedUser();
      // const { sub } = attributes;

      // Set the name in the ulvc2 backend
      await VoiceClonerAPI.setUserData({
        name: name,
      });

      // Set the name in ms-auth
      await API.post("userData", "", {
        body: [
          { key: "firstname", value: firstName },
          { key: "lastname", value: lastName },
          // { key: "company", value: company },
        ],
      });
      await dispatch(checkUser());
      return name;
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  }
);

/**
 * Handles the user logging out
 */
export const signOut = createAsyncThunk("signOut", async () => {
  await Auth.signOut();
});

/*===================================================================*/
/* Reducer Logic
/*===================================================================*/

/**
 * Auth Reducer
 */
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCollectName: (state, action: PayloadAction<boolean>) => {
      state.collectName = action.payload;
    },
  },
  extraReducers: (builder) => {
    /**
     * Sign Up
     */
    builder.addCase(signUp.pending, (state) => {
      state.signUpLoading = true;
      state.signUpError = "";
    });
    builder.addCase(signUp.fulfilled, (state) => {
      state.signUpLoading = false;
    });
    builder.addCase(signUp.rejected, (state, action) => {
      state.signUpLoading = false;
      // @ts-expect-error Will always be string
      state.signUpError = action.payload;
    });

    /**
     * Sign In
     */
    builder.addCase(signIn.pending, (state) => {
      state.signInLoading = true;
      state.signInError = "";
      state.loggedIn = false;
      state.email = "";
    });
    builder.addCase(
      signIn.fulfilled,
      (state, action: PayloadAction<string>) => {
        state.signInLoading = false;
        state.authStage = "CONFIRMATION";
        state.email = action.payload;
      }
    );
    builder.addCase(signIn.rejected, (state, action) => {
      // @ts-expect-error Will always be string
      state.signInError = action.payload;
      state.signInLoading = false;
    });

    /**
     * Confirmation Code
     */
    builder.addCase(submitCode.pending, (state) => {
      state.codeLoading = true;
      state.codeError = "";
    });
    builder.addCase(submitCode.fulfilled, (state) => {
      state.codeLoading = false;
      state.codeError = "";
    });
    builder.addCase(submitCode.rejected, (state, action) => {
      state.codeLoading = false;
      // @ts-expect-error Will always be string
      state.codeError = action.payload;
    });

    /**
     * Check User
     */
    builder.addCase(checkUser.pending, (state) => {
      state.checkUserLoading = true;
      state.checkUserError = "";
    });
    builder.addCase(
      checkUser.fulfilled,
      (
        state,
        action: PayloadAction<{
          username: string;
          email: string;
          org: string;
          role: string;
          sub: string;
          name: string;
        }>
      ) => {
        state.checkUserLoading = false;
        state.loggedIn = true;
        state.authStage = "LOGGED_IN";
        state.username = action.payload.username;
        state.email = action.payload.email;
        state.org = action.payload.org;
        state.role = action.payload.role;
        state.sub = action.payload.sub;
        state.name = action.payload.name;
      }
    );
    builder.addCase(checkUser.rejected, (state, action) => {
      state.checkUserLoading = false;
      // @ts-expect-error Will always be string
      state.checkUserError = action.payload;
      state.loggedIn = false;
      state.authStage = "SIGN_IN";
    });

    /**
     * Sign Out
     */
    builder.addCase(signOut.fulfilled, (state) => {
      state.loggedIn = false;
      state.authStage = "SIGN_IN";
      state.email = "";
      state.org = "";
      state.role = "";
      state.sub = "";
    });

    /**
     * Submit Name
     */
    builder.addCase(submitName.pending, (state) => {
      state.nameLoading = true;
      state.nameError = "";
    });
    builder.addCase(
      submitName.fulfilled,
      (state, action: PayloadAction<string>) => {
        state.nameLoading = false;
        state.authStage = "LOGGED_IN";
        state.username = action.payload;
        state.collectName = false;
      }
    );
    builder.addCase(submitName.rejected, (state, action) => {
      state.nameLoading = false;
      // @ts-expect-error Will always be string
      state.nameError = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const { setCollectName } = authSlice.actions;
export default authSlice.reducer;
